/* Deprecated Component */

import * as Yup from "yup";
import { Formik } from "formik";
import React, { useCallback } from "react";

/* Components */
import { Button, Input, Textarea, Toast } from "@components";

/* Hooks */
import { useUniqueId } from "@src/hooks";

/* APIs */
import { useContactAPI } from "@src/service/home";

/* Types */
import type { ContactPayload } from "@src/service/home";

type Props = {
  children?: React.ReactNode;
  onSubmit?: () => void;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  institution: Yup.string(),
  email: Yup.string().email(),
  details: Yup.string().required(),
  phone: Yup.string().required(),
});

export const ContactForm: React.FC<Props> = (props) => {
  const { onSubmit: onSubmitCb } = props;

  /* APIs & Hooks */
  const mut = useContactAPI();

  /* Memos & Callbacks */
  const toastId = useUniqueId("toast-");
  const onSubmit = useCallback(
    async (values: ContactPayload) => {
      Toast.loading("Submitting enquiry...", { id: toastId });
      try {
        await mut.mutateAsync(values);
        Toast.success("Sucessfully submitted enquiry", { id: toastId });
        onSubmitCb?.();
      } catch (e) {
        Toast.error("Failed to submit enquiry. Please try again", {
          id: toastId,
        });
      }
    },
    [mut, onSubmitCb, toastId],
  );

  return (
    <Formik
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnMount={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      initialValues={{
        name: "",
        institution: "",
        email: "",
        details: "",
        phone: "",
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="flex flex-col gap-1 w-full">
          <div className="flex w-full flex-col lg:flex-row gap-4">
            <Input label="Name" name="name" required />
            <Input label="Institution(Optional)" name="institution" />
          </div>
          <div className="flex gap-4 w-full flex-col lg:flex-row">
            <Input name="email" label="Email (Optional)" />
            <Input name="phone" label="Phone" />
          </div>
          <Textarea name="details" required label="Message" rows={2} />
          <Button
            onClick={handleSubmit as any}
            disabled={isSubmitting}
            size="lg"
          >
            Submit
          </Button>
        </div>
      )}
    </Formik>
  );
};
